import PropTypes from 'prop-types'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const MONTH_FORMAT = 'MMMM YYYY'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const SIMPLIFIED_DATETIME_FORMAT = 'DD.MM HH:mm'
export const COLORS = [
  'default',
  'warning',
  'success',
  'danger',
  'info',
  'secondary',
  'accent',
  'white-with-accent',
  'blue',
  'surprise',
  'prototyping'
]
export const DAY_START_HOUR = 6

function withPropType (obj) {
  obj.propType = PropTypes.oneOf(Object.values(obj))
  return obj
}

export const Characters = withPropType({
  PRODUCTION: 'production',
  STOPPAGE: 'stoppage',
  STOPPAGE_PLANNED: 'stoppagePlanned',
  STOPPAGE_UNPLANNED: 'stoppageUnplanned',
  CHANGEOVER: 'changeover',
  SCRAP: 'scrap',
  UNREPORTED: 'unreported'
})
